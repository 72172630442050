<template>
  <router-link to="/manage">
    <v-img
      src="@assets/nivi_logotype.png"
      contain
      height="40"
      width="100"
      position="left center"
    />
  </router-link>
</template>

<script>
import utils from '@mixins/utils.js'

export default {
  name: 'AppBarLogo',
  mixins: [utils],
}
</script>
