<template>
  <v-app>
    <v-app-bar
      app
      flat
      :color="visualSettings.appbarColor"
      :dark="visualSettings.appbarDark"
      style="z-index:11"
    >
      <v-container>
        <AppBarLogo />
      </v-container>
    </v-app-bar>
    <v-main style="z-index: 10">
      <v-container class="fill-height">
        <v-row justify="space-between">
          <v-col md="5" lg="5" xl="4">
            <div
              :class="{
                'mt-16': $vuetify.breakpoint.mdAndUp,
                'mt-8': !$vuetify.breakpoint.mdAndUp,
                'white--text': true,
              }"
            >
              <h1
                class="text-sm-h2 font-weight-medium white--text"
                v-html="visualSettings.frontPageTitle"
              />
              <h2 class="text-h5 hidden-sm-and-down">
                {{ visualSettings.frontPageSubtitle }}
              </h2>

              <v-btn
                v-if="visualSettings.displayCta"
                rounded
                outlined
                dark
                x-large
                class="mt-8 px-16 font-weight-black  hidden-sm-and-down"
                href="https://www.windtrebusiness.it/grandi-aziende/servizi"
              >
                SCOPRI IL CATALOGO
              </v-btn>
            </div>
          </v-col>
          <v-col md="5" lg="4" xl="3">
            <slot />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-img
      v-if="visualSettings.loginBackground"
      :src="visualSettings.loginBackground"
      class="bg fill-height"
    />

    <v-footer
      v-if="visualSettings.footerVisible"
      :color="visualSettings.footerColor"
      :dark="visualSettings.footerDark"
    >
      <v-container>
        <!--
        <v-img
          v-if="visualSettings.footerDark"
          src="@assets/ennova_logotype_negative.svg"
          contain
          width="200"
          height="48"
          class="mt-4 mb-4"
        />
        -->
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
// import FrontpageLogo from '@components/common/FrontpageLogo.vue'
import { version } from '@/package.json'
import AppBarLogo from '@components/structure/app-bar-logo.vue'
import utils from '@mixins/utils.js'

export default {
  components: {
    AppBarLogo,
    // FrontpageLogo,
    // LoginArt: () => import('@components/icons/LoginArt.vue'),
  },
  mixins: [utils],

  data: () => ({
    version,
  }),

  created() {
    this.$vuetify.theme.dark = false

    // Prevent the install app from showing
    window.addEventListener('beforeinstallprompt', this.preventDefault)
  },

  beforeDestroy() {
    window.removeEventListener('beforeinstallprompt', this.preventDefault)
  },
  methods: {
    preventDefault(event) {
      event.preventDefault()
    },
  },
}
</script>

<style>
#app {
  background-color: var(--v-background-base);
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  /*z-index: -100;*/
}

.footer-bottom-list a {
  color: #f2f2f2 !important;
  text-decoration: none;
}

.footer-bottom-list a:hover {
  text-decoration: underline !important;
}
</style>
